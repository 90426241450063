.af-view .w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .w-commerce-commercecartwrapper {
  display: inline-block;
  position: relative;
}

.af-view .w-commerce-commercecartopenlink {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.af-view .w-commerce-commercecartopenlinkcount {
  height: 18px;
  min-width: 18px;
  color: #3898ec;
  text-align: center;
  background-color: #fff;
  border-radius: 9px;
  margin-left: 8px;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 11px;
  font-weight: 700;
  line-height: 18px;
  display: inline-block;
}

.af-view .w-commerce-commercecartcontainerwrapper {
  z-index: 1001;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.af-view .w-commerce-commercecartcontainerwrapper--cartType-modal {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.af-view .w-commerce-commercecartcontainerwrapper--cartType-leftSidebar {
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  display: flex;
}

.af-view .w-commerce-commercecartcontainerwrapper--cartType-rightSidebar {
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  display: flex;
}

.af-view .w-commerce-commercecartcontainerwrapper--cartType-leftDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: 0;
  right: auto;
}

.af-view .w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 100%;
  bottom: auto;
  left: auto;
  right: 0;
}

.af-view .w-commerce-commercecartcontainer {
  width: 100%;
  max-width: 480px;
  min-width: 320px;
  background-color: #fff;
  flex-direction: column;
  display: flex;
  overflow: auto;
  box-shadow: 0 5px 25px rgba(0, 0, 0, .25);
}

.af-view .w-commerce-commercecartheader {
  border-bottom: 1px solid #e6e6e6;
  flex: none;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  display: flex;
  position: relative;
}

.af-view .w-commerce-commercecartheading {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.af-view .w-commerce-commercecartcloselink {
  width: 16px;
  height: 16px;
}

.af-view .w-commerce-commercecartformwrapper {
  flex-direction: column;
  flex: 1;
  display: flex;
}

.af-view .w-commerce-commercecartform {
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  display: flex;
}

.af-view .w-commerce-commercecartlist {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  padding: 12px 24px;
  overflow: auto;
}

.af-view .w-commerce-commercecartitem {
  align-items: flex-start;
  padding-top: 12px;
  padding-bottom: 12px;
  display: flex;
}

.af-view .w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.af-view .w-commerce-commercecartiteminfo {
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.af-view .w-commerce-commercecartproductname {
  font-weight: 700;
}

.af-view .w-commerce-commercecartoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.af-view .w-commerce-commercecartquantity {
  height: 38px;
  width: 60px;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecartquantity::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecartquantity::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecartquantity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecartfooter {
  border-top: 1px solid #e6e6e6;
  flex-direction: column;
  flex: none;
  padding: 16px 24px 24px;
  display: flex;
}

.af-view .w-commerce-commercecartlineitem {
  flex: none;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 16px;
  display: flex;
}

.af-view .w-commerce-commercecartordervalue {
  font-weight: 700;
}

.af-view .w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  min-height: 30px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.af-view .w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.af-view .w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  height: 38px;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.af-view .w-commerce-commercequickcheckoutgoogleicon, .af-view .w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.af-view .w-commerce-commercecartcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.af-view .w-commerce-commercecartemptystate {
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
}

.af-view .w-commerce-commercecarterrorstate {
  background-color: #ffdede;
  flex: none;
  margin: 0 24px 24px;
  padding: 10px;
}

.af-view .w-commerce-commercecheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.af-view .w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.af-view .w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.af-view .w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.af-view .w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.af-view .w-commerce-commercecheckoutemailinput {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutemailinput::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutshippingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutshippingfullname::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.af-view .w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.af-view .w-commerce-commercecheckoutshippingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutshippingcity::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutshippingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutshippingstateprovince::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutshippingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutshippingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutshippingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutshippingcountryselector::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.af-view .w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.af-view .w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.af-view .w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.af-view .w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.af-view .w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutcardnumber {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutcardnumber::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutcardnumber:focus, .af-view .w-commerce-commercecheckoutcardnumber.af-class--wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutcardexpirationdate {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutcardexpirationdate::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutcardexpirationdate:focus, .af-view .w-commerce-commercecheckoutcardexpirationdate.af-class--wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutcardsecuritycode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutcardsecuritycode::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutcardsecuritycode:focus, .af-view .w-commerce-commercecheckoutcardsecuritycode.af-class--wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.af-view .w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.af-view .w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.af-view .w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutbillingfullname {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutbillingfullname::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddress {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddress::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddressoptional {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddressoptional::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutbillingcity {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutbillingcity::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutbillingstateprovince {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutbillingstateprovince::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutbillingzippostalcode {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutbillingzippostalcode::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutbillingcountryselector {
  height: 38px;
  width: 100%;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commercecheckoutbillingcountryselector::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.af-view .w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.af-view .w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.af-view .w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.af-view .w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.af-view .w-commerce-commercecheckoutorderitemquantitywrapper {
  white-space: pre-wrap;
  display: flex;
}

.af-view .w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.af-view .w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.af-view .w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutsummarylineitem, .af-view .w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.af-view .w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.af-view .w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.af-view .w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.af-view .w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.af-view .w-checkbox:before {
  content: " ";
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.af-view .w-checkbox:after {
  content: " ";
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.af-view .w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.af-view .w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.af-view .w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.af-view .w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.af-view .w-commerce-commerceaddtocartform {
  margin: 0 0 15px;
}

.af-view .w-commerce-commerceaddtocartoptionpillgroup {
  margin-bottom: 10px;
  display: flex;
}

.af-view .w-commerce-commerceaddtocartoptionpill {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  margin-right: 10px;
  padding: 8px 15px;
}

.af-view .w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.af-view .w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.af-view .w-commerce-commerceaddtocartbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.af-view .w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.af-view .w-commerce-commerceaddtocartquantityinput {
  height: 38px;
  width: 60px;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.af-view .w-commerce-commerceaddtocartquantityinput::-ms-input-placeholder {
  color: #999;
}

.af-view .w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.af-view .w-commerce-commerceaddtocartquantityinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.af-view .w-commerce-commercebuynowbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  margin-top: 10px;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.af-view .w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.af-view .w-commerce-commerceaddtocartoutofstock {
  background-color: #ddd;
  margin-top: 10px;
  padding: 10px;
}

.af-view .w-commerce-commerceaddtocarterror {
  background-color: #ffdede;
  margin-top: 10px;
  padding: 10px;
}

.af-view .w-commerce-commerceorderconfirmationcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.af-view .w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.af-view .w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercecheckoutsummaryitem, .af-view .w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.af-view .w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.af-view .w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: .33em;
}

.af-view .w-commerce-commercecheckoutshippingsummarywrapper, .af-view .w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.af-view .w-commerce-commercepaypalcheckoutformcontainer {
  width: 100%;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}

.af-view .w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.af-view .w-pagination-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.af-view .w-pagination-previous, .af-view .w-pagination-next {
  color: #333;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 9px 20px;
  font-size: 14px;
  display: block;
}

.af-view .w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.af-view .w-form-formradioinput--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.af-view .w-form-formradioinput--inputType-custom.w--redirected-checked {
  border-width: 4px;
  border-color: #3898ec;
}

@media screen and (max-width: 767px) {
  .af-view .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .af-view .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }

  .af-view .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }
}

@media screen and (max-width: 479px) {
  .af-view .w-commerce-commercecartcontainerwrapper--cartType-modal {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
  }

  .af-view .w-commerce-commercecartcontainerwrapper--cartType-leftDropdown, .af-view .w-commerce-commercecartcontainerwrapper--cartType-rightDropdown {
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .af-view .w-commerce-commercecartquantity, .af-view .w-commerce-commercecheckoutemailinput, .af-view .w-commerce-commercecheckoutshippingfullname, .af-view .w-commerce-commercecheckoutshippingstreetaddress, .af-view .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .af-view .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .af-view .w-commerce-commercecheckoutshippingcity, .af-view .w-commerce-commercecheckoutshippingstateprovince, .af-view .w-commerce-commercecheckoutshippingzippostalcode, .af-view .w-commerce-commercecheckoutshippingcountryselector, .af-view .w-commerce-commercecheckoutcardnumber, .af-view .w-commerce-commercecheckoutcardexpirationdate, .af-view .w-commerce-commercecheckoutcardsecuritycode, .af-view .w-commerce-commercecheckoutbillingfullname, .af-view .w-commerce-commercecheckoutbillingstreetaddress, .af-view .w-commerce-commercecheckoutbillingstreetaddressoptional, .af-view .w-commerce-commercecheckoutbillingcity, .af-view .w-commerce-commercecheckoutbillingstateprovince, .af-view .w-commerce-commercecheckoutbillingzippostalcode, .af-view .w-commerce-commercecheckoutbillingcountryselector, .af-view .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}

.af-view {
  color: #343434;
  font-family: Satoshi, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.af-view h1 {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 48px;
  font-weight: 400;
  line-height: 54px;
}

.af-view h2 {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
}

.af-view h3 {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
}

.af-view h4 {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
}

.af-view h5 {
  margin-top: 0;
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.af-view h6 {
  margin-top: 0;
  margin-bottom: 7px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.af-view p {
  margin-bottom: 28px;
}

.af-view a {
  color: #343434;
  letter-spacing: .02em;
  font-weight: 600;
  text-decoration: none;
}

.af-view ul {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

.af-view label {
  letter-spacing: .05em;
  text-transform: uppercase;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: block;
}

.af-view blockquote {
  border-left: 5px #e2e2e2;
  margin-top: 21px;
  margin-bottom: 21px;
  padding: 0;
  font-size: 28px;
  font-style: italic;
  font-weight: 500;
  line-height: 36px;
}

.af-view figure {
  margin-bottom: 10px;
}

.af-view figcaption {
  text-align: center;
  text-transform: uppercase;
  margin-top: 14px;
  font-size: 14px;
  line-height: 21px;
}

.af-view .af-class-section {
  padding-top: 42px;
  padding-bottom: 42px;
}

.af-view .af-class-section.af-class-bg-primary-dark {
  background-color: #ada4a4;
}

.af-view .af-class-section.af-class-bg-primary-dark.af-class-about-brand {
  display: none;
}

.af-view .af-class-section.af-class-no-bottom-padding {
  padding-bottom: 0;
}

.af-view .af-class-section.af-class-reduced-bottom-padding, .af-view .af-class-section.af-class-shop-title {
  padding-bottom: 56px;
}

.af-view .af-class-section.af-class-bg-primary-light.af-class-more-from-brand {
  display: none;
}

.af-view .af-class-section.af-class-order-confirmation-section {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 21px;
  padding-right: 21px;
}

.af-view .af-class-section.af-class-changelog-section {
  min-height: 50vh;
}

.af-view .af-class-section.af-class-more-about-us {
  display: none;
}

.af-view .af-class-main-container {
  width: 100%;
  max-width: 1328px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px;
}

.af-view .af-class-style-guide {
  grid-row-gap: 112px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.af-view .af-class-style-guide-section {
  grid-column-gap: 56px;
  grid-row-gap: 84px;
  grid-template-rows: auto;
  grid-template-columns: .33fr 1fr;
}

.af-view .af-class-style-guide-colors {
  grid-column-gap: 28px;
  grid-row-gap: 7px;
  background-color: #fff;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.af-view .af-class-style-guide-color {
  width: 100%;
  height: 168px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  position: relative;
}

.af-view .af-class-bg-primary {
  color: #fff;
  background-color: #ada4a4;
}

.af-view .af-class-bg-primary-dark {
  color: #fff;
  background-color: #474040;
}

.af-view .af-class-bg-dark {
  color: #fff;
  background-color: #343434;
}

.af-view .af-class-bg-white {
  background-color: #fff;
}

.af-view .af-class-bg-white.af-class-border {
  border: 1px solid #e0e0e0;
}

.af-view .af-class-style-guide-section-contents {
  grid-row-gap: 28px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  align-items: start;
  justify-items: start;
}

.af-view .af-class-large-text {
  font-size: 22px;
  line-height: 32px;
}

.af-view .af-class-small-text {
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-no-bottom-margin {
  margin-bottom: 0;
}

.af-view .af-class-button {
  color: #343434;
  text-align: center;
  background-color: #cfcac7;
  padding: 11px 42px 14px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition: color .2s, background-color .2s;
}

.af-view .af-class-button:hover {
  color: #fff;
  background-color: #ada4a4;
}

.af-view .af-class-button.af-class-small {
  border-style: none;
  padding: 10px 35px 13px;
  font-size: 14px;
  line-height: 21px;
}

.af-view .af-class-button.af-class-small.af-class-nav-button {
  display: none;
}

.af-view .af-class-button.af-class-bg-primary-dark {
  color: #fff;
  background-color: #474040;
}

.af-view .af-class-button.af-class-bg-primary-dark:hover {
  background-color: #ada4a4;
}

.af-view .af-class-button.af-class-bg-primary {
  color: #fff;
  background-color: #ada4a4;
}

.af-view .af-class-button.af-class-bg-primary:hover {
  background-color: #474040;
}

.af-view .af-class-button.af-class-bg-white {
  background-color: #fff;
}

.af-view .af-class-button.af-class-bg-white:hover {
  background-color: #ada4a4;
}

.af-view .af-class-button.af-class-bg-white-faded {
  color: #fff;
  background-color: rgba(255, 255, 255, .2);
}

.af-view .af-class-button.af-class-bg-white-faded:hover {
  color: #343434;
  background-color: #fff;
}

.af-view .af-class-button.af-class-no-top-margin {
  margin-top: 0;
}

.af-view .af-class-navbar {
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  justify-content: center;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 21px;
  display: flex;
}

.af-view .af-class-form-block {
  width: 100%;
  margin-bottom: 0;
}

.af-view .af-class-form-input {
  height: 66px;
  border: 1px solid #e0e0e0;
  margin-bottom: 0;
  padding: 21px;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-form-input::-ms-input-placeholder {
  color: #343434;
}

.af-view .af-class-form-input::placeholder {
  color: #343434;
}

.af-view .af-class-form-input.af-class-text-area {
  min-height: 150px;
}

.af-view .af-class-form-input.af-class-subscribe-form-input {
  font-size: 18px;
  line-height: 28px;
}

.af-view .af-class-form-grid {
  grid-column-gap: 16px;
  grid-row-gap: 21px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 0;
  display: grid;
}

.af-view .af-class-selectable-field {
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.af-view .af-class-selectable-label {
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 0;
  margin-left: 14px;
  font-weight: 500;
}

.af-view .af-class-back-to-top-button {
  z-index: 10;
  width: 56px;
  height: 56px;
  background-color: #ada4a4;
  position: fixed;
  bottom: 14px;
  right: 14px;
}

.af-view .af-class-announcement-bar {
  background-color: #cfcac7;
  align-items: center;
  display: block;
  overflow: hidden;
}

.af-view .af-class-announcement-cross {
  width: 28px;
  height: 28px;
  cursor: pointer;
  display: block;
  position: absolute;
  right: 28px;
}

.af-view .af-class-announcement-contents {
  font-size: 14px;
  line-height: 21px;
}

.af-view .af-class-search-modal-screen {
  background-color: rgba(0, 0, 0, .5);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.af-view .af-class-utility-page-wrap {
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.af-view .af-class-utility-page-content {
  width: 497px;
  text-align: center;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: 21px;
  margin-right: 21px;
  display: flex;
}

.af-view .af-class-utility-page-form {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.af-view .af-class-select-wrapper {
  border: 1px solid #343434;
  padding: 7px;
  overflow: hidden;
}

.af-view .af-class-select-field {
  width: 110%;
  background-color: rgba(0, 0, 0, 0);
  background-image: url('../images/icon-select-arrow.svg');
  background-position: 90%;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: 1px #000;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-bg-primary-light {
  background-color: #cfcac7;
}

.af-view .af-class-bg-grey {
  background-color: #e0e0e0;
}

.af-view .af-class-checkbox {
  width: 21px;
  height: 21px;
  background-color: #e0e0e0;
  border-style: none;
  border-radius: 0;
  margin-top: 0;
  margin-left: 0;
}

.af-view .af-class-checkbox.w--redirected-checked {
  background-color: #e0e0e0;
  background-image: url('../images/icon-check.svg');
  background-position: 50%;
  background-size: auto;
  border-style: none;
}

.af-view .af-class-radio {
  width: 21px;
  height: 21px;
  background-color: #e0e0e0;
  border-style: none;
  margin-top: 0;
  margin-left: 0;
}

.af-view .af-class-radio.w--redirected-checked {
  background-color: #fff;
  border-style: solid;
  border-color: #343434;
}

.af-view .af-class-style-guide-row-heading {
  margin-bottom: 0;
}

.af-view .af-class-nav-menu-row {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.af-view .af-class-announcement-text {
  text-align: center;
  padding-top: 14px;
  padding-bottom: 14px;
}

.af-view .af-class-style-guide-color-label {
  position: absolute;
  bottom: 21px;
  left: 21px;
}

.af-view .af-class-form-success {
  background-color: #cfcac7;
  padding: 21px;
}

.af-view .af-class-form-error {
  text-align: center;
  margin-top: 14px;
  padding: 14px;
  font-size: 14px;
  line-height: 21px;
}

.af-view .af-class-fine-print-text {
  font-size: 14px;
  line-height: 21px;
}

.af-view .af-class-fine-print-text.af-class-form-hint-text {
  opacity: .7;
  margin-top: 14px;
}

.af-view .af-class-fine-print-text.af-class-muted {
  opacity: .75;
}

.af-view .af-class-fine-print-text.af-class-remove-link {
  opacity: .5;
  transition: opacity .2s;
}

.af-view .af-class-fine-print-text.af-class-remove-link:hover {
  opacity: 1;
}

.af-view .af-class-uppercase-text {
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 21px;
}

.af-view .af-class-uppercase-text.af-class-links-grid-title {
  margin-bottom: 14px;
}

.af-view .af-class-uppercase-text.af-class-marquee-text {
  flex: none;
}

.af-view .af-class-uppercase-text.af-class-article-date, .af-view .af-class-uppercase-text.af-class-brand {
  opacity: .75;
  margin-bottom: 3px;
}

.af-view .af-class-uppercase-text.af-class-product-details-heading {
  margin-bottom: 14px;
  font-weight: 500;
}


.af-view .af-class-button-wrapper {
  background-color: #343434;
  padding: 21px;
}

.af-view .af-class-icon-grid {
  width: 100%;
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}

.af-view .af-class-navbar-top {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-top: 14px;
  padding-bottom: 14px;
  display: grid;
}

.af-view .af-class-navbar-right-contents {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.af-view .af-class-nav-cart-button {
  background-color: rgba(0, 0, 0, 0);
  margin-left: 21px;
  padding: 0;
}

.af-view .af-class-cart-quantity {
  height: 21px;
  min-width: 21px;
  color: #fff;
  background-color: #343434;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-left: 7px;
  display: flex;
}

.af-view .af-class-nav-search-form {
  align-items: center;
  margin-bottom: 0;
  display: flex;
}

.af-view .af-class-nav-search-form.af-class-nav-search-mobile.af-class-search-body {
  display: none;
}

.af-view .af-class-search-form-input {
  color: #343434;
  border: 1px #000;
  margin-bottom: 0;
  padding: 7px 0 7px 14px;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-search-form-input::-ms-input-placeholder {
  color: #343434;
}

.af-view .af-class-search-form-input::placeholder {
  color: #343434;
}

.af-view .af-class-hide-button {
  display: none;
}

.af-view .af-class-navbar-categories {
  align-items: center;
  display: flex;
}

.af-view .af-class-nav-link {
  margin-left: 14px;
  margin-right: 14px;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  transition: opacity .2s;
  display: block;
}

.af-view .af-class-nav-link:hover {
  opacity: .5;
}

.af-view .af-class-nav-menu-divider {
  width: 1px;
  height: 21px;
  background-color: #e0e0e0;
  margin-left: 14px;
  margin-right: 14px;
}

.af-view .af-class-div-block {
  z-index: 10;
  background-color: #fff;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.af-view .af-class-top {
  height: 0;
  position: absolute;
  top: 0;
}

.af-view .af-class-footer-subscribe-section {
  grid-column-gap: 56px;
  grid-row-gap: 56px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  padding-top: 112px;
  padding-bottom: 112px;
  display: grid;
}

.af-view .af-class-subscribe-form {
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
  padding-bottom: 7px;
  display: flex;
}

.af-view .af-class-subscribe-form-input {
  border: 1px #000;
  margin-bottom: 0;
  padding-left: 0;
}

.af-view .af-class-footer-details-section {
  padding-top: 56px;
  padding-bottom: 56px;
}

.af-view .af-class-footer-grid {
  grid-column-gap: 56px;
  grid-row-gap: 56px;
  grid-template-rows: auto;
  grid-template-columns: 444px 1fr 1fr;
  margin-bottom: 70px;
}

.af-view .af-class-footer-contact-details {
  border-right: 1px solid #e0e0e0;
  flex-direction: column;
  padding-right: 56px;
  display: flex;
}

.af-view .af-class-footer-logo {
  width: 400px;
  max-height: 200px;
  min-height: 28px;
  object-fit: cover;
}

.af-view .af-class-contact-links {
  flex-direction: column;
  align-items: flex-start;
  margin-top: 7px;
  display: flex;
}

.af-view .af-class-footer-logo-link {
  flex: 1;
  margin-bottom: 21px;
}

.af-view .af-class-links-grid {
  grid-column-gap: 16px;
  grid-row-gap: 7px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .af-class-list-link {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.af-view .af-class-list-link.w--current {
  font-weight: 700;
}

.af-view .af-class-footer-fine-print {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.af-view .af-class-underline-link {
  font-weight: 500;
  text-decoration: underline;
}

.af-view .af-class-social-links {
  justify-content: center;
  align-items: center;
  margin-left: -14px;
  margin-right: -14px;
  display: flex;
}

.af-view .af-class-social-link {
  flex: none;
  margin-left: 14px;
  margin-right: 14px;
}

.af-view .af-class-grid-halves {
  grid-column-gap: 56px;
  grid-row-gap: 56px;
  grid-template-rows: auto;
  align-items: center;
  justify-items: center;
}

.af-view .af-class-social-images {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
}

.af-view .af-class-text-center {
  text-align: center;
}

.af-view .af-class-medium-heading {
  margin-bottom: 14px;
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
}

.af-view .af-class-medium-heading.af-class-no-bottom-margin {
  margin-bottom: 0;
}

.af-view .af-class-button-row {
  align-items: center;
  margin-top: 28px;
  display: flex;
}

.af-view .af-class-button-row.af-class-justify-content-center {
  justify-content: center;
}

.af-view .af-class-grid-halves-section {
  max-width: 1680px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.af-view .af-class-container {
  width: 100%;
  max-width: 497px;
}

.af-view .af-class-container.af-class-align-center {
  margin-left: auto;
  margin-right: auto;
}

.af-view .af-class-container.af-class-justify-content-between {
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  display: flex;
}

.af-view .af-class-grid-halves-image {
  display: block;
}

.af-view .af-class-marquee-section {
  width: 100%;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 28px;
  display: block;
  overflow: hidden;
}

.af-view .af-class-marquee-text {
  text-transform: uppercase;
}

.af-view .af-class-marquee-contents {
  flex-wrap: nowrap;
  align-items: center;
  display: flex;
}

.af-view .af-class-marquee-icon {
  flex: none;
  margin-left: 21px;
  margin-right: 21px;
}

.af-view .af-class-portrait-image-left-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 387px 1fr;
}

.af-view .af-class-section-beside-image {
  flex-direction: column;
  justify-content: flex-end;
  padding: 56px;
  display: flex;
}

.af-view .af-class-square-image-left-grid {
  max-width: 1272px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 84px;
  padding-bottom: 84px;
}

.af-view .af-class-portrait-image-right-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 497px;
}

.af-view .af-class-section-beside-image-heading {
  flex: 1;
}

.af-view .af-class-container-large {
  width: 100%;
  max-width: 829px;
}

.af-view .af-class-container-large.af-class-align-center {
  margin-left: auto;
  margin-right: auto;
}

.af-view .af-class-section-title {
  margin-bottom: 35px;
}

.af-view .af-class-section-title.af-class-increased-margin {
  margin-bottom: 56px;
}

.af-view .af-class-section-title.af-class-more-from-brand-title {
  align-items: center;
  display: flex;
}

.af-view .af-class-process-grid {
  grid-row-gap: 14px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.af-view .af-class-process-item {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  background-color: #cfcac7;
  grid-template-rows: auto;
  grid-template-columns: 1fr 497px;
  padding: 35px;
}

.af-view .af-class-empty-state {
  text-align: center;
  background-color: #cfcac7;
  padding: 28px;
}

.af-view .af-class-empty-state.af-class-bg-primary {
  background-color: #ada4a4;
}

.af-view .af-class-journal-grid {
  grid-column-gap: 49px;
  grid-row-gap: 49px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .af-class-journal-link {
  font-weight: 500;
}

.af-view .af-class-journal-thumbnail {
  margin-bottom: 21px;
}

.af-view .af-class-featured-article {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
}

.af-view .af-class-featured-article-title-container {
  color: #fff;
  background-color: #474040;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 56px;
  display: flex;
}

.af-view .af-class-featured-article-link {
  color: #fff;
  font-weight: 500;
}

.af-view .af-class-article-container {
  width: 100%;
  max-width: 608px;
  margin-left: auto;
  margin-right: auto;
}

.af-view .af-class-article-date {
  margin-bottom: 3px;
}

.af-view .af-class-article-date.af-class-increased-margin {
  margin-bottom: 7px;
}

.af-view .af-class-article-body {
  margin-top: 84px;
}

.af-view .af-class-article-body blockquote {
  margin-top: 56px;
  margin-bottom: 56px;
}

.af-view .af-class-article-body ul {
  grid-column-gap: 16px;
  grid-row-gap: 7px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-top: 28px;
  margin-bottom: 28px;
  display: grid;
}

.af-view .af-class-article-body figure {
  margin-top: 56px;
  margin-bottom: 56px;
}

.af-view .af-class-article-body h3 {
  margin-top: 35px;
}

.af-view .af-class-product-quarters-grid {
  grid-column-gap: 49px;
  grid-row-gap: 49px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .af-class-product-preview-link {
  font-weight: 500;
  display: block;
}

.af-view .af-class-product-thumbnail {
  margin-bottom: 21px;
  display: block;
}

.af-view .af-class-price-container {
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  display: flex;
}

.af-view .af-class-price-container.af-class-large-text {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 35px;
  padding-bottom: 35px;
  font-size: 22px;
  line-height: 32px;
}

.af-view .af-class-old-price {
  opacity: .7;
  margin-right: 7px;
  text-decoration: line-through;
}

.af-view .af-class-old-price.af-class-increased-margin {
  margin-right: 14px;
}

.af-view .af-class-sidebar-layout-grid {
  grid-column-gap: 49px;
  grid-row-gap: 49px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr;
  align-items: start;
  position: relative;
}

.af-view .af-class-sidebar {
  grid-column-gap: 16px;
  grid-row-gap: 35px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  position: -webkit-sticky;
  position: sticky;
  top: 88px;
}

.af-view .af-class-product-thirds-grid {
  grid-column-gap: 49px;
  grid-row-gap: 49px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .af-class-brand-grid {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 497px 1fr;
}

.af-view .af-class-brand-section {
  display: none;
}

.af-view .af-class-product-grid {
  grid-column-gap: 56px;
  grid-row-gap: 56px;
  grid-template-rows: auto;
  grid-template-columns: 1fr auto 1fr;
  align-items: start;
  position: relative;
}

.af-view .af-class-vertical-border {
  width: 1px;
  background-color: #e0e0e0;
}

.af-view .af-class-breadcrumbs {
  align-items: center;
  margin-bottom: 28px;
  font-size: 14px;
  line-height: 21px;
  display: flex;
}

.af-view .af-class-breadcrumb-arrow {
  margin-left: 7px;
  margin-right: 7px;
}

.af-view .af-class-product-details-grid {
  grid-row-gap: 42px;
  grid-template-columns: 1fr;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-product-details-list ul {
  margin-bottom: 0;
  padding-left: 28px;
}

.af-view .af-class-product-dimensions {
  grid-column-gap: 21px;
  grid-row-gap: 21px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 14px;
  padding-bottom: 14px;
  display: grid;
}

.af-view .af-class-dimensions-heading {
  opacity: .5;
}

.af-view .af-class-product-gallery {
  /* grid-column-gap: 16px; */
  /* grid-row-gap: 56px; */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .af-class-product-description {
  position: -webkit-sticky;
  position: sticky;
  top: 88px;
}

.af-view .af-class-quantity-label {
  letter-spacing: 0;
  text-transform: none;
  margin-bottom: 0;
  margin-right: 7px;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-add-to-cart-form {
  border: 0 solid #e0e0e0;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  display: flex;
}

.af-view .af-class-quantity-wrapper {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: none;
}

.af-view .af-class-quantity-input {
  margin-bottom: 0;
}

.af-view .af-class-quantity-input:focus {
  border: 1px solid #474040;
}

.af-view .af-class-cart-default-state {
  /* grid-column-gap: 16px;
  grid-row-gap: 21px; */
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-bottom: 0;
  display: grid;
}

.af-view .af-class-main-product-image {
  position: -webkit-sticky;
  position: sticky;
  top: 88px;
}

.af-view .af-class-pagination {
  margin-top: 56px;
}

.af-view .af-class-pagination-arrow {
  margin-left: 7px;
  position: relative;
  bottom: 2px;
}

.af-view .af-class-pagination-arrow.af-class-left {
  margin-left: 0;
  margin-right: 7px;
  transform: rotate(180deg);
}

.af-view .af-class-featured-products-grid {
  grid-column-gap: 49px;
  grid-row-gap: 49px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr;
  align-items: center;
}

.af-view .af-class-large-heading {
  margin-bottom: 14px;
  font-size: 48px;
  font-weight: 400;
  line-height: 54px;
}

.af-view .af-class-cart-container {
  max-width: 441px;
  padding: 28px;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-cart-list {
  grid-column-gap: 16px;
  grid-row-gap: 21px;
  flex: 1;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  align-content: start;
  align-items: start;
  padding: 0;
  display: grid;
}

.af-view .af-class-cart-header {
  border-bottom-style: solid;
  border-bottom-color: #e0e0e0;
  margin-bottom: 21px;
  padding: 0 0 21px;
}

.af-view .af-class-cart-footer {
  border-top-color: #e0e0e0;
  margin-top: 21px;
  padding: 21px 0 0;
}

.af-view .af-class-close-cart-button {
  width: 24px;
  height: 24px;
}

.af-view .af-class-cart-item {
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 500;
}

.af-view .af-class-cart-item-image {
  width: 84px;
  height: auto;
}

.af-view .af-class-cart-item-details {
  margin-left: 21px;
  margin-right: 21px;
}

.af-view .af-class-checkout-form {
  grid-column-gap: 28px;
  grid-row-gap: 28px;
  background-color: rgba(0, 0, 0, 0);
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  display: grid;
}

.af-view .af-class-order-confirmation-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  line-height: 24px;
}

.af-view .af-class-checkout-heading {
  margin-bottom: 0;
}

.af-view .af-class-block-header {
  background-color: #cfcac7;
  border-color: #e0e0e0;
  padding: 21px;
}

.af-view .af-class-web-payments {
  margin-bottom: 28px;
}

.af-view .af-class-apple-pay-button {
  height: 49px;
}

.af-view .af-class-checkout-sidebar {
  top: 88px;
}

.af-view .af-class-changelog-grid {
  grid-template-rows: auto;
  grid-template-columns: 1fr 3fr;
}

.af-view .af-class-licenses-link {
  color: #fff;
  background-color: #343434;
  justify-content: center;
  align-items: center;
  display: flex;
}

.af-view .af-class-licenses-image {
  opacity: .5;
}

.af-view .af-class-license-text {
  position: absolute;
}

.af-view .af-class-typography-demo {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.af-view .af-class-typography-demo-text {
  margin-bottom: 7px;
  font-size: 96px;
  line-height: 96px;
}

.af-view .af-class-search-results-grid {
  grid-column-gap: 49px;
  grid-row-gap: 49px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .af-class-search-result-item {
  flex-direction: column;
  display: flex;
}

.af-view .af-class-search-results-image {
  order: -1;
  margin-bottom: 21px;
}

.af-view .af-class-search-result-url {
  display: none;
}

.af-view .af-class-search-result-link {
  margin-bottom: 14px;
}

.af-view .af-class-image {
  width: 450px;
  max-height: 200px;
  object-fit: cover;
}

.af-view .af-class-divblock-template-footer {
  display: none;
}

.af-view .af-class-journal-section {
  padding-top: 84px;
  padding-bottom: 84px;
  display: none;
}

.af-view .af-class-journal-section.af-class-no-bottom-padding {
  padding-bottom: 0;
}

.af-view .af-class-journal-section.af-class-reduced-bottom-padding, .af-view .af-class-journal-section.af-class-shop-title {
  padding-bottom: 56px;
}

.af-view .af-class-journal-section.af-class-order-confirmation-section {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 21px;
  padding-right: 21px;
}

.af-view .af-class-journal-section.af-class-changelog-section {
  min-height: 50vh;
}

.af-view .af-class-social-link-ie {
  flex: none;
  margin-left: 14px;
  margin-right: 14px;
}

.af-view .af-class-social-link-li, .af-view .af-class-social-link-g {
  flex: none;
  margin-left: 14px;
  margin-right: 14px;
  display: none;
}

.af-view .af-class-section-followus {
  padding-top: 84px;
  padding-bottom: 84px;
}

.af-view .af-class-section-followus.af-class-bg-primary-dark {
  background-color: #ada4a4;
  display: none;
}

.af-view .af-class-section-followus.af-class-no-bottom-padding {
  padding-bottom: 0;
}

.af-view .af-class-section-followus.af-class-reduced-bottom-padding, .af-view .af-class-section-followus.af-class-shop-title {
  padding-bottom: 56px;
}

.af-view .af-class-section-followus.af-class-order-confirmation-section {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 21px;
  padding-right: 21px;
}

.af-view .af-class-section-followus.af-class-changelog-section {
  min-height: 50vh;
}

.af-view .af-class-list-link-journal {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: none;
}

.af-view .af-class-list-link-journal.w--current {
  font-weight: 700;
}

.af-view .af-class-about-section-hero {
  padding-top: 84px;
  padding-bottom: 84px;
  display: none;
}

.af-view .af-class-about-section-hero.af-class-bg-primary-dark {
  background-color: #ada4a4;
}

.af-view .af-class-about-section-hero.af-class-no-bottom-padding {
  padding-bottom: 0;
}

.af-view .af-class-about-section-hero.af-class-reduced-bottom-padding, .af-view .af-class-about-section-hero.af-class-shop-title {
  padding-bottom: 56px;
}

.af-view .af-class-about-section-hero.af-class-order-confirmation-section {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 21px;
  padding-right: 21px;
}

.af-view .af-class-about-section-hero.af-class-changelog-section {
  min-height: 50vh;
}

.af-view .af-class-about-section-process {
  padding-top: 84px;
  padding-bottom: 84px;
  display: none;
}

.af-view .af-class-about-section-process.af-class-bg-primary-dark {
  background-color: #ada4a4;
}

.af-view .af-class-about-section-process.af-class-no-bottom-padding {
  padding-bottom: 0;
}

.af-view .af-class-about-section-process.af-class-reduced-bottom-padding, .af-view .af-class-about-section-process.af-class-shop-title {
  padding-bottom: 56px;
}

.af-view .af-class-about-section-process.af-class-order-confirmation-section {
  background-color: rgba(0, 0, 0, 0);
  padding-left: 21px;
  padding-right: 21px;
}

.af-view .af-class-about-section-process.af-class-changelog-section {
  min-height: 50vh;
}

.af-view .af-class-uui-navbar3_menu-button {
  padding: 0;
}

.af-view .af-class-uui-button {
  grid-column-gap: .5rem;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #7f56d9;
  border: 1px solid #7f56d9;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.af-view .af-class-uui-button:hover {
  background-color: #6941c6;
  border-color: #6941c6;
}

.af-view .af-class-uui-button:focus {
  background-color: #7f56d9;
  box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f4ebff;
}

.af-view .af-class-uui-button-tertiary-gray {
  grid-column-gap: .5rem;
  color: #475467;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.af-view .af-class-uui-button-tertiary-gray:hover {
  color: #344054;
  background-color: #f9fafb;
}

.af-view .af-class-uui-button-secondary-gray {
  grid-column-gap: .5rem;
  color: #344054;
  text-align: center;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  border-radius: .5rem;
  justify-content: center;
  align-items: center;
  padding: .625rem 1.125rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all .3s;
  display: flex;
  box-shadow: 0 1px 2px rgba(16, 24, 40, .05);
}

.af-view .af-class-uui-button-secondary-gray:hover {
  color: #1d2939;
  background-color: #f9fafb;
}

.af-view .af-class-uui-button-secondary-gray:focus {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(16, 24, 40, .05), 0 0 0 4px #f2f4f7;
}

.af-view .af-class-uui-button-secondary-gray.af-class-show-tablet, .af-view .af-class-show-tablet {
  display: none;
}

.af-view .af-class-uui-navbar03_button-wrapper {
  grid-column-gap: .75rem;
  grid-row-gap: .75rem;
  align-items: center;
  display: flex;
}

.af-view .af-class-uui-navbar03_menu-right {
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  display: flex;
}

.af-view .af-class-uui-navbar03_link {
  color: #475467;
  align-items: center;
  padding: 1.75rem 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  transition: all .3s;
}

.af-view .af-class-uui-navbar03_link:hover {
  color: #344054;
}

.af-view .af-class-uui-navbar03_link.w--current {
  color: #000;
}

.af-view .af-class-uui-button-icon {
  width: 1.25rem;
  height: 1.25rem;
  min-height: 1.25rem;
  min-width: 1.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.af-view .af-class-uui-button-link {
  grid-column-gap: .5rem;
  color: #6941c6;
  white-space: nowrap;
  background-color: rgba(0, 0, 0, 0);
  justify-content: center;
  align-items: center;
  padding: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-decoration: none;
  transition: all .3s;
  display: flex;
}

.af-view .af-class-uui-button-link:hover {
  color: #53389e;
}

.af-view .af-class-uui-navbar03_bottom-bar-right {
  grid-column-gap: 1.5rem;
  display: flex;
  position: relative;
}

.af-view .af-class-uui-text-size-medium {
  color: #475467;
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

.af-view .af-class-uui-navbar03_bottom-bar-left {
  grid-column-gap: .5rem;
  grid-template-rows: auto;
  grid-template-columns: max-content minmax(max-content, 1fr);
  grid-auto-columns: 1fr;
  font-weight: 500;
  display: flex;
  position: relative;
}

.af-view .af-class-uui-navbar03_container {
  width: 100%;
  height: 100%;
  max-width: 80rem;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.af-view .af-class-uui-navbar03_bottom-bar-background {
  width: 200vw;
  background-color: #f9fafb;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: -50vw;
  right: -50vw;
}

.af-view .af-class-uui-navbar03_bottom-bar {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  position: relative;
}

.af-view .af-class-uui-text-size-small {
  color: #475467;
  letter-spacing: normal;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: .875rem;
  line-height: 1.5;
}

.af-view .af-class-uui-navbar03_item-heading {
  color: #101828;
  margin-bottom: .25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-weight: 600;
}

.af-view .af-class-uui-navbar03_item-heading.af-class-margin-bottom-0 {
  margin-bottom: 0;
}

.af-view .af-class-uui-navbar03_item-right {
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 400;
  display: flex;
}

.af-view .af-class-uui-icon-1x1-xsmall {
  width: 1.5rem;
  height: 1.5rem;
}

.af-view .af-class-icon-featured-large {
  width: 3rem;
  height: 3rem;
  color: #7f56d9;
  background-color: #f4ebff;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.af-view .af-class-uui-navbar03_icon-wrapper {
  flex: none;
}

.af-view .af-class-uui-navbar03_dropdown-link {
  grid-column-gap: 1rem;
  grid-row-gap: 0px;
  border-radius: .5rem;
  grid-template-rows: auto;
  grid-template-columns: max-content 1fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  padding: .75rem;
  text-decoration: none;
  transition: background-color .3s;
  display: grid;
}

.af-view .af-class-uui-navbar03_dropdown-link:hover {
  background-color: #f9fafb;
}

.af-view .af-class-uui-badge-small-success {
  grid-column-gap: .25rem;
  color: #027a48;
  white-space: nowrap;
  mix-blend-mode: multiply;
  background-color: #ecfdf3;
  border-radius: 10rem;
  align-items: center;
  padding: .125rem .5rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: .75rem;
  font-weight: 500;
  display: flex;
}

.af-view .af-class-uui-navbar03_text-wrapper {
  grid-column-gap: .5rem;
  grid-row-gap: 0px;
  align-items: center;
  margin-bottom: .25rem;
  display: flex;
}

.af-view .af-class-uui-navbar03_heading {
  color: #7f56d9;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: .25rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: .875rem;
  font-weight: 600;
}

.af-view .af-class-uui-navbar03_dropdown-link-list {
  grid-column-gap: 0px;
  grid-row-gap: .5rem;
  grid-template-rows: max-content max-content max-content max-content max-content;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.af-view .af-class-uui-navbar03_dropdown-content-left {
  grid-column-gap: 1.5rem;
  grid-row-gap: 0px;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 0;
  display: grid;
}

.af-view .af-class-uui-navbar03_dropdown-content {
  width: 100%;
  display: flex;
}

.af-view .af-class-uui-navbar03_dropdown-list {
  box-shadow: 0 12px 16px -4px rgba(0, 0, 0, .08), 0 4px 6px -2px rgba(16, 24, 40, .03);
}

.af-view .af-class-uui-navbar03_dropdown-list.w--open {
  width: 100vw;
  max-width: 100%;
  min-width: 100vw;
  background-color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
  position: absolute;
  top: 5.0625rem;
  bottom: auto;
  left: 0%;
  right: auto;
  overflow: hidden;
}

.af-view .af-class-uui-dropdown-icon {
  width: 1.25rem;
  height: 1.25rem;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  display: flex;
  position: absolute;
  top: auto;
  bottom: auto;
  left: auto;
  right: 0%;
}

.af-view .af-class-uui-navbar03_dropdown-toggle {
  color: #475467;
  align-items: center;
  padding: 1.75rem 2.5rem 1.75rem 1rem;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  transition: all .3s;
  display: flex;
}

.af-view .af-class-uui-navbar03_dropdown-toggle:hover {
  color: #344054;
}

.af-view .af-class-uui-navbar03_menu-dropdown {
  position: static;
}

.af-view .af-class-uui-navbar03_menu {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.5rem;
  display: flex;
  position: static;
}

.af-view .af-class-uui-logo_image {
  width: auto;
  height: 100%;
  flex: none;
  display: none;
}

.af-view .af-class-uui-logo_logotype {
  width: auto;
  height: 100%;
  flex: none;
}

.af-view .af-class-uui-logo_logomark-dot {
  z-index: 1;
  width: 50%;
  height: 50%;
  background-image: linear-gradient(26.5deg, #6941c6, #53389e);
  border-radius: 50%;
  position: relative;
}

.af-view .af-class-uui-logo_logomark-blur {
  z-index: 2;
  width: 100%;
  height: 50%;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, .2);
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  overflow: hidden;
}

.af-view .af-class-uui-styleguide_logomark-bg {
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.af-view .af-class-uui-logo_logomark {
  width: 2rem;
  height: 2rem;
  border: .1px solid #dae0e8;
  border-radius: .5rem;
  flex: none;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 1px rgba(16, 24, 40, .06), 0 1px 2px rgba(16, 24, 40, .1);
}

.af-view .af-class-uui-logo_component {
  width: auto;
  height: 2rem;
  flex-wrap: nowrap;
  display: flex;
}

.af-view .af-class-uui-navbar03_logo-link {
  padding-left: 0;
}

.af-view .af-class-uui-navbar03_component {
  min-height: 5rem;
  background-color: #fff;
  border-bottom: 1px solid #f2f4f7;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  display: none;
}

.af-view .af-class-navbar-logo {
  width: 0;
  display: none;
}

.af-view .af-class-nav-container {
  width: 100%;
  max-width: 1328px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 28px;
  padding-right: 28px;
}

.af-view .af-class-nav-button-menu {
  color: #343434;
  text-align: center;
  background-color: #cfcac7;
  padding: 11px 42px 14px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  transition: color .2s, background-color .2s;
}

.af-view .af-class-nav-button-menu:hover {
  color: #fff;
  background-color: #ada4a4;
}

.af-view .af-class-nav-button-menu.af-class-small {
  border-style: none;
  padding: 10px 35px 13px;
  font-size: 14px;
  line-height: 21px;
}

.af-view .af-class-nav-button-menu.af-class-bg-primary-dark {
  color: #fff;
  background-color: #474040;
}

.af-view .af-class-nav-button-menu.af-class-bg-primary-dark:hover {
  background-color: #ada4a4;
}

.af-view .af-class-nav-button-menu.af-class-bg-primary {
  color: #fff;
  background-color: #ada4a4;
}

.af-view .af-class-nav-button-menu.af-class-bg-primary:hover {
  background-color: #474040;
}

.af-view .af-class-nav-button-menu.af-class-bg-white {
  background-color: #fff;
}

.af-view .af-class-nav-button-menu.af-class-bg-white:hover {
  background-color: #ada4a4;
}

.af-view .af-class-nav-button-menu.af-class-bg-white-faded {
  color: #fff;
  background-color: rgba(255, 255, 255, .2);
}

.af-view .af-class-nav-button-menu.af-class-bg-white-faded:hover {
  color: #343434;
  background-color: #fff;
}

.af-view .af-class-nav-button-menu.af-class-no-top-margin {
  margin-top: 0;
}

.af-view .af-class-home-square-image-left-grid {
  max-width: 1272px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 84px;
  padding-bottom: 84px;
}

.af-view .af-class-by-brand {
  display: none;
}

.af-view .af-class-navbar-hamburger {
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #e0e0e0;
  border-bottom: 0 solid #e0e0e0;
  justify-content: center;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 21px;
}

.af-view .af-class-nav-menu {
  display: none;
}

@media screen and (max-width: 991px) {
  .af-view .af-class-section.af-class-shop-title {
    padding-bottom: 0;
  }

  .af-view .af-class-style-guide-section {
    grid-row-gap: 56px;
    grid-template-columns: 1fr;
  }

  .af-view .af-class-no-bottom-margin.af-class-above-brand {
    width: 100%;
    max-width: 608px;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-footer-subscribe-section {
    max-width: 608px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-footer-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .af-view .af-class-footer-contact-details {
    text-align: center;
    border-bottom: 1px solid #e0e0e0;
    border-right-style: none;
    align-items: center;
    padding-bottom: 56px;
    padding-right: 0;
  }

  .af-view .af-class-contact-links.af-class-in-footer {
    align-items: center;
  }

  .af-view .af-class-grid-halves {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-medium-heading.af-class-no-bottom-margin.af-class-journal-heading {
    max-width: 608px;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-button-row.af-class-featured-items-button-row {
    margin-top: 0;
  }

  .af-view .af-class-grid-halves-section {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-grid-halves-image {
    width: 100%;
    max-width: 608px;
  }

  .af-view .af-class-portrait-image-left-grid {
    max-width: 497px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-square-image-left-grid {
    width: 100%;
    max-width: 608px;
    grid-template-columns: 1fr;
  }

  .af-view .af-class-portrait-image-right-grid {
    max-width: 497px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-section-title.af-class-above-journal-grid {
    max-width: 497px;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-process-item {
    grid-template-columns: 1fr 2fr;
  }

  .af-view .af-class-journal-grid {
    max-width: 497px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-featured-article {
    max-width: 608px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-product-quarters-grid {
    grid-column-gap: 28px;
    grid-row-gap: 56px;
    grid-template-columns: 1fr 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-sidebar-layout-grid {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-sidebar {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    position: static;
  }

  .af-view .af-class-product-thirds-grid {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-columns: 1fr 1fr;
  }

  .af-view .af-class-product-thirds-grid.af-class-featured-products {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .af-view .af-class-brand-grid {
    max-width: 608px;
    grid-template-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
  }

  .af-view .af-class-brand-section {
    padding-top: 56px;
  }

  .af-view .af-class-product-grid {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-vertical-border {
    display: none;
  }

  .af-view .af-class-product-gallery {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-columns: 1fr 1fr;
  }

  .af-view .af-class-product-description {
    max-width: 608px;
    margin-left: auto;
    margin-right: auto;
    position: static;
  }

  .af-view .af-class-main-product-image {
    width: 100%;
    max-width: 608px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    position: static;
  }

  .af-view .af-class-more-images-grid {
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
    /* padding-top: 56px !important; */
  }

  .af-view .af-class-featured-products-grid {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-large-heading.af-class-featured-items-heading {
    margin-bottom: 0;
  }

  .af-view .af-class-featured-items-title {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .af-view .af-class-checkout-form {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-checkout-sidebar {
    position: static;
  }

  .af-view .af-class-search-results-grid {
    grid-column-gap: 28px;
    grid-template-columns: 1fr 1fr;
  }

  .af-view .af-class-image {
    width: 300px;
    max-height: 150px;
  }

  .af-view .af-class-journal-section.af-class-shop-title, .af-view .af-class-section-followus.af-class-shop-title, .af-view .af-class-about-section-hero.af-class-shop-title, .af-view .af-class-about-section-process.af-class-shop-title {
    padding-bottom: 0;
  }

  .af-view .af-class-menu-icon_line-bottom {
    width: 24px;
    height: 2px;
    background-color: #344054;
    border-radius: 1rem;
    padding-bottom: 0;
    padding-right: 0;
  }

  .af-view .af-class-menu-icon_line-middle-inner {
    width: 4px;
    height: 0;
    padding-bottom: 0;
    padding-right: 0;
  }

  .af-view .af-class-menu-icon_line-middle {
    width: 24px;
    height: 2px;
    background-color: #344054;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
    margin-bottom: 6px;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .af-view .af-class-menu-icon_line-top {
    width: 24px;
    height: 2px;
    background-color: #344054;
    border-radius: 1rem;
    padding-bottom: 0;
    padding-right: 0;
  }

  .af-view .af-class-menu-icon_component {
    width: 48px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -.5rem;
    padding-bottom: 0;
    padding-right: 0;
    display: flex;
  }

  .af-view .af-class-uui-navbar3_menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .af-view .af-class-hide-tablet {
    display: none;
  }

  .af-view .af-class-uui-button-secondary-gray.af-class-show-tablet, .af-view .af-class-show-tablet {
    display: block;
  }

  .af-view .af-class-uui-navbar03_button-wrapper {
    flex-direction: column-reverse;
    align-items: stretch;
    display: flex;
  }

  .af-view .af-class-uui-navbar03_menu-right {
    flex-direction: column-reverse;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    align-items: stretch;
    margin-top: 1.5rem;
    padding-bottom: 7rem;
  }

  .af-view .af-class-uui-navbar03_link {
    width: 100%;
    color: #101828;
    padding: .75rem 0;
    font-size: 1.125rem;
  }

  .af-view .af-class-uui-navbar03_link:hover {
    color: #101828;
  }

  .af-view .af-class-uui-navbar03_bottom-bar-left {
    margin-bottom: 2rem;
  }

  .af-view .af-class-uui-navbar03_bottom-bar-background {
    width: 100%;
    border-radius: .5rem;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
  }

  .af-view .af-class-uui-navbar03_bottom-bar {
    border-radius: .5rem;
    margin-bottom: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: none;
  }

  .af-view .af-class-uui-navbar03_dropdown-link {
    padding-left: 0;
    padding-right: 0;
  }

  .af-view .af-class-uui-navbar03_dropdown-link:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .af-view .af-class-uui-navbar03_dropdown-content-left {
    grid-row-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
    align-content: start;
    align-items: start;
    padding-left: 0;
    padding-right: 0;
  }

  .af-view .af-class-uui-navbar03_dropdown-content {
    flex-direction: column;
  }

  .af-view .af-class-uui-navbar03_dropdown-list {
    position: relative;
    overflow: hidden;
  }

  .af-view .af-class-uui-navbar03_dropdown-list.w--open {
    min-width: auto;
    box-shadow: none;
    border-style: none;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    padding: 0 0 0;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
  }

  .af-view .af-class-uui-dropdown-icon {
    color: #667085;
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 0;
    top: auto;
    bottom: auto;
    left: auto;
    right: 0%;
  }

  .af-view .af-class-uui-navbar03_dropdown-toggle {
    align-items: center;
    padding-top: .75rem;
    padding-bottom: .75rem;
    padding-left: 0;
    font-size: 1.125rem;
    display: flex;
  }

  .af-view .af-class-uui-navbar03_dropdown-toggle:hover {
    color: #101828;
  }

  .af-view .af-class-uui-navbar03_menu-dropdown {
    width: 100%;
    position: relative;
  }

  .af-view .af-class-uui-navbar03_menu-left {
    grid-column-gap: 0px;
    grid-row-gap: .5rem;
    color: #101828;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
  }

  .af-view .af-class-uui-navbar03_menu {
    height: 100vh;
    -webkit-text-fill-color: inherit;
    background-color: #fff;
    background-clip: border-box;
    border-top: 1px solid #f2f4f7;
    align-items: flex-start;
    margin-left: 0;
    padding: 1.5rem 2rem 5rem;
    position: absolute;
    overflow: auto;
  }

  .af-view .af-class-uui-navbar03_component {
    display: none;
  }

  .af-view .af-class-home-square-image-left-grid {
    width: 100%;
    max-width: 608px;
    grid-template-columns: 1fr;
  }

  .af-view .af-class-nav-mobile {
    border-bottom: 0 #000;
  }
}

@media screen and (max-width: 767px) {
  .af-view {
    font-size: 16px;
    line-height: 24px;
  }

  .af-view h1 {
    font-size: 40px;
    line-height: 48px;
  }

  .af-view h2 {
    font-size: 32px;
    line-height: 42px;
  }

  .af-view h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .af-view .af-class-section {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .af-view .af-class-button.af-class-bg-primary.af-class-wide.w--open {
    width: 90%;
  }

  .af-view .af-class-button.af-class-wide {
    width: 100%;
  }

  .af-view .af-class-nav-menu-row {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    background-color: #fff;
    flex-direction: column;
    padding: 14px 14px 21px;
  }

  .af-view .af-class-announcement-text {
    text-align: left;
    padding-right: 56px;
  }

  .af-view .af-class-navbar-top {
    grid-template-columns: 1fr 1fr;
    justify-items: start;
  }

  .af-view .af-class-navbar-right-contents {
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }

  .af-view .af-class-nav-search-form {
    width: 100%;
    border-top: 1px solid #e0e0e0;
    padding-top: 14px;
  }

  .af-view .af-class-nav-search-form.af-class-nav-search-mobile {
    display: none;
  }

  .af-view .af-class-nav-search-form.af-class-nav-search-mobile.af-class-search-body {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 14px;
    padding-left: 37px;
    padding-right: 37px;
    display: flex;
  }

  .af-view .af-class-navbar-categories {
    flex-direction: column;
  }

  .af-view .af-class-nav-menu-divider {
    width: 21px;
    height: 1px;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .af-view .af-class-div-block {
    display: none;
  }

  .af-view .af-class-footer-logo {
    width: 300px;
    height: 100px;
  }

  .af-view .af-class-footer-fine-print {
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    text-align: center;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    justify-items: center;
    display: grid;
  }

  .af-view .af-class-medium-heading {
    font-size: 32px;
    line-height: 42px;
  }

  .af-view .af-class-section-beside-image {
    padding: 42px;
  }

  .af-view .af-class-square-image-left-grid {
    padding-top: 0;
    padding-bottom: 0;
  }

  .af-view .af-class-process-item {
    grid-template-columns: 2fr;
  }

  .af-view .af-class-article-body {
    margin-top: 56px;
  }

  .af-view .af-class-article-body blockquote {
    margin-top: 35px;
    margin-bottom: 35px;
    font-size: 24px;
    line-height: 32px;
  }

  .af-view .af-class-sidebar-layout-grid {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-product-thirds-grid {
    grid-row-gap: 56px;
  }

  .af-view .af-class-product-thirds-grid.af-class-featured-products {
    grid-template-columns: 1fr 1fr;
  }

  .af-view .af-class-large-heading {
    font-size: 40px;
    line-height: 48px;
  }

  .af-view .af-class-image {
    max-height: 100px;
  }

  .af-view .af-class-journal-section, .af-view .af-class-section-followus, .af-view .af-class-about-section-hero, .af-view .af-class-about-section-process {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .af-view .af-class-menu-icon_component {
    margin-right: 30px;
  }

  .af-view .af-class-uui-navbar3_menu-button.w--open {
    opacity: 0;
    margin-right: 0;
  }

  .af-view .af-class-uui-button {
    font-size: 1rem;
  }

  .af-view .af-class-uui-button:hover {
    background-color: #7f56d9;
    border-color: #7f56d9;
  }

  .af-view .af-class-uui-button-tertiary-gray:hover {
    color: #475467;
    background-color: rgba(0, 0, 0, 0);
  }

  .af-view .af-class-uui-button-secondary-gray {
    padding-left: 1.25rem;
    font-size: 1rem;
  }

  .af-view .af-class-uui-button-secondary-gray:hover {
    color: #344054;
    background-color: #fff;
  }

  .af-view .af-class-uui-navbar03_link, .af-view .af-class-uui-button-link {
    font-size: 1rem;
  }

  .af-view .af-class-uui-button-link:hover {
    color: #6941c6;
  }

  .af-view .af-class-uui-badge-small-success {
    padding: .125rem .5rem;
  }

  .af-view .af-class-uui-navbar03_dropdown-link-list {
    max-width: none;
    grid-row-gap: .5rem;
  }

  .af-view .af-class-uui-navbar03_dropdown-content-left {
    grid-row-gap: 1.5rem;
    flex-direction: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .af-view .af-class-uui-navbar03_dropdown-toggle {
    font-size: 1rem;
  }

  .af-view .af-class-uui-navbar03_menu {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .af-view .af-class-uui-navbar03_component {
    min-height: 4.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .af-view .af-class-navbar-logo {
    width: 150px;
    height: 60px;
    opacity: 0;
    object-fit: cover;
    margin-left: 30px;
    margin-right: auto;
    display: block;
    position: relative;
  }

  .af-view .af-class-nav-button-menu.af-class-bg-primary.af-class-wide {
    background-color: rgba(0, 0, 0, 0);
    padding-bottom: 11px;
    padding-left: 11px;
    padding-right: 11px;
  }

  .af-view .af-class-nav-button-menu.af-class-bg-primary.af-class-wide.w--open {
    width: 90%;
  }

  .af-view .af-class-nav-button-menu.af-class-wide {
    width: 100%;
  }

  .af-view .af-class-home-square-image-left-grid {
    padding-top: 0;
    padding-bottom: 0;
  }

  .af-view .af-class-nav-mobile {
    z-index: 99;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .af-view .af-class-navbar-hamburger {
    border-top-width: 0;
    border-bottom-width: 0;
    padding-left: 21px;
    padding-right: 21px;
    display: block;
  }

  .af-view .af-class-cart {
    padding: 11px 11px 11px 0;
  }

  .af-view .af-class-image-2 {
    width: 25px;
  }
}

@media screen and (max-width: 479px) {
  .af-view h1 {
    font-size: 32px;
    line-height: 42px;
  }

  .af-view h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .af-view h3, .af-view h4 {
    font-size: 24px;
    line-height: 32px;
  }

  .af-view h5 {
    font-size: 18px;
    line-height: 28px;
  }

  .af-view p {
    margin-bottom: 24px;
  }

  .af-view .af-class-main-container {
    padding-left: 21px;
    padding-right: 21px;
  }

  .af-view .af-class-style-guide-section {
    grid-row-gap: 35px;
  }

  .af-view .af-class-style-guide-colors {
    grid-column-gap: 21px;
    grid-row-gap: 21px;
  }

  .af-view .af-class-style-guide-section-contents {
    grid-row-gap: 21px;
  }

  .af-view .af-class-large-text {
    font-size: 18px;
    line-height: 28px;
  }

  .af-view .af-class-button.af-class-small.af-class-nav-button {
    display: none;
  }

  .af-view .af-class-form-input {
    height: 56px;
    padding: 14px;
  }

  .af-view .af-class-form-input.af-class-subscribe-form-input {
    border-style: solid;
    border-color: #e0e0e0;
    margin-bottom: 14px;
  }

  .af-view .af-class-footer-subscribe-section {
    padding-top: 84px;
    padding-bottom: 84px;
  }

  .af-view .af-class-subscribe-form {
    border-bottom-style: none;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 0;
  }

  .af-view .af-class-footer-grid {
    grid-row-gap: 35px;
    text-align: center;
    grid-template-columns: 1fr;
  }

  .af-view .af-class-footer-logo {
    width: 250px;
  }

  .af-view .af-class-grid-halves {
    grid-row-gap: 35px;
  }

  .af-view .af-class-medium-heading {
    font-size: 28px;
    line-height: 38px;
  }

  .af-view .af-class-button-row, .af-view .af-class-button-row.af-class-featured-items-button-row {
    margin-top: 21px;
  }

  .af-view .af-class-section-beside-image {
    padding: 28px;
  }

  .af-view .af-class-process-item {
    grid-row-gap: 14px;
    padding: 28px;
  }

  .af-view .af-class-article-body blockquote {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .af-view .af-class-product-quarters-grid {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-price-container.af-class-large-text {
    margin-bottom: 28px;
    padding-bottom: 28px;
  }

  .af-view .af-class-sidebar, .af-view .af-class-product-thirds-grid, .af-view .af-class-product-thirds-grid.af-class-featured-products {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-breadcrumbs {
    margin-bottom: 21px;
  }

  .af-view .af-class-product-details-grid {
    grid-row-gap: 28px;
  }

  .af-view .af-class-product-dimensions {
    grid-row-gap: 14px;
    grid-template-columns: 1fr;
  }

  .af-view .af-class-product-gallery {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-add-to-cart-form {
    flex-direction: column;
  }

  .af-view .af-class-quantity-wrapper {
    margin-bottom: 14px;
  }

  .af-view .af-class-large-heading {
    font-size: 32px;
    line-height: 42px;
  }

  .af-view .af-class-featured-items-title {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .af-view .af-class-cart-container {
    padding: 21px;
  }

  .af-view .af-class-cart-item-image {
    width: 56px;
  }

  .af-view .af-class-search-results-grid {
    grid-template-columns: 1fr;
  }

  .af-view .af-class-image {
    max-height: 50px;
  }

  .af-view .af-class-uui-navbar03_bottom-bar-right {
    width: 100%;
    flex-direction: column;
  }

  .af-view .af-class-uui-navbar03_bottom-bar-left {
    grid-template-rows: auto auto;
    grid-template-columns: max-content;
  }

  .af-view .af-class-uui-navbar03_bottom-bar {
    align-items: flex-start;
  }

  .af-view .af-class-uui-navbar03_dropdown-link {
    padding-left: 0;
  }

  .af-view .af-class-uui-navbar03_dropdown-content-left {
    grid-template-columns: 1fr;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .af-view .af-class-nav-button-menu.af-class-small.af-class-nav-button {
    display: none;
  }
}

.af-view #w-node-_6516a443-e082-ba54-bf5e-2ac8d24e6979-734c7867 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

.af-view #w-node-_3ee0633f-a568-72e0-ab7b-589be9d960dd-e9d960d8 {
  justify-self: stretch;
}

.af-view #w-node-dab1a8fd-38ef-ff9e-e737-a0bdf6abf40d-f6abf40a {
  grid-area: 1 / 1 / 2 / 2;
}

.af-view #w-node-e50559be-f2c1-034b-d254-91311050b643-734c78ba {
  grid-area: 1 / 2 / 2 / 3;
}

.af-view #w-node-_804c12a1-9bc5-17bd-b4fd-53a9435a8b1b-734c78ba {
  justify-self: start;
}

.af-view #w-node-c7e51d0f-2d88-d3ea-4d4e-74172b8dc048-734c78be {
  align-self: stretch;
}

.af-view #w-node-beb45f57-ee6b-1472-4564-b43975a06909-734c78cb {
  justify-self: stretch;
}

@media screen and (max-width: 991px) {
  .af-view #w-node-_3ee0633f-a568-72e0-ab7b-589be9d960ed-e9d960d8 {
    grid-area: span 1 / span 3 / span 1 / span 3;
  }

  .af-view #w-node-e50559be-f2c1-034b-d254-91311050b643-734c78ba {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}

@media screen and (max-width: 767px) {
  .af-view #w-node-dab1a8fd-38ef-ff9e-e737-a0bdf6abf413-f6abf40a {
    justify-self: end;
  }

  .af-view #w-node-dab1a8fd-38ef-ff9e-e737-a0bdf6abf40d-f6abf40a {
    grid-area: span 1 / span 2 / span 1 / span 2;
  }
}

@media screen and (max-width: 479px) {
  .af-view #w-node-_3ee0633f-a568-72e0-ab7b-589be9d960ed-e9d960d8 {
    grid-column: span 1 / span 1;
  }
}


@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
.disable-scroll {
  overflow: hidden;
}

a[href^="http://"] {
  background: url(http://upload.wikimedia.org/wikipedia/commons/6/64/Icon_External_Link.png)     center right no-repeat;
  padding-right: 13px;
}

.basket-count {
  font-size: 12px; /* Adjust as needed */
  color: #888;      /* Adjust as needed */
  display: flex;    /* Enable flexbox for alignment */
  align-items: center; /* Align dot vertically with text */
}

.breathing-dot {
  width: 8px;       /* Adjust size as needed */
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px; /* Add space between dot and text */
  animation: breathing 2s ease-in-out infinite; /* Breathing animation */
}

@keyframes breathing {
  0% {
    opacity: 0.2;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.8);
  }
}

.low-stock-message {
  font-size: 12px;      /* Adjust as needed */
  color: #dc3545;       /* Bootstrap danger color */
  background-color: #f8d7da; /* Bootstrap danger background */
  border-radius: 5px;    /* Rounded corners */
  padding: 5px 10px;     /* Add padding */
  margin-top: 5px;       /* Adjust as needed */
  display: flex;         /* Enable flexbox for alignment */
  align-items: center;  /* Align dot vertically with text */
}

/* Loading state (add this) */
.your-button.loading {
  opacity: 0.5; /* or similar visual cue */
  pointer-events: none; /* prevent interaction while loading */
}

.your-button.loading::after { /* or ::before */
  content: ""; /* Your loading text */
  /* Position and style as needed */
}

/* In your global CSS file or a stylesheet linked in your React component */
.w-commerce-commercecheckoutshippingmethoditem input[type="radio"] {
  /* Resetting default styles */
  appearance: none; /* Remove default appearance */
  width: 16px;      /* Adjust size as needed */
  height: 16px;
  border: 2px solid #ccc;
  border-radius: 50%; /* Make it circular */
  margin-right: 8px;  /* Add some space between the radio and label */

  /* Add custom styles */
  background-color: white; /* Customize background color */

  /* Checked state */
  &:checked {
    background-color:  #888;
    background-color: #796a6a;
   
  }

  /* Optional: Hover state */
  &:hover {
    background-color: #474040;
  }
}
